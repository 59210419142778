#rvw_dev_tools {
    .devtool-show-toolbar {
        background: #222;
        border-radius: 0;
        border: 2px solid #666;
        border-bottom: 0;
        border-left: 0;
        bottom: 0;
        display: block;
        height: 40px;
        left: 0;
        margin: 0;
        padding: 0;
        position: fixed;
        transition: all 0.25s;
        width: 40px;
        z-index: 999999;

        svg {
            width: 24px;
            height: 24px;
            fill: #FFF;
        }

        &:hover {
            background-color: #000;
        }
    }
}
