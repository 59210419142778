$toolbar-height: 40px;
$toolbar-color-debug: #6d980a;
$toolbar-color-error: #d70000;
$toolbar-color-fatal: #990000;
$toolbar-color-info: #286981;
$toolbar-color-log: #444444;
$toolbar-color-warn: #d65507;

$drawer-sidebar-width: 160px;
$drawer-sidebar-width-mobile: 50px;
