#rvw_dev_tools {
    .devtool-popover {
        background: #000;
        border-radius: 0;
        border: none;
        bottom: 40px;
        color: #FFF;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        left: 0;
        padding: 0;
        position: absolute;
        width: auto;
        z-index: 99999;
        padding: 14px 0;

        &.fade-enter-active:before,
        &.fade-leave-active:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(34, 34, 34, 0.85);
            z-index: 9999;
        }

        &.fade-enter-active:after,
        &.fade-leave-active:after {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            border: 2px solid #fff;
            border-left-color: transparent;
            border-radius: 50%;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            animation: devtools_loading 1s linear infinite;
            z-index: 10000;
        }

        .popover__arrow {
            display: none;
        }

        .table {
            text-align: left;
            line-height: 24px;
            margin: 0;
            padding: 0;

            & > button {
                background: transparent;
                border: none;
                color: white;
                display: block;
                font-size: 14px;
                height: 24px;
                line-height: 24px;
                margin: 0;
                padding: 0 14px;
                position: relative;
                text-align: left;
                transition: all 0.25s;

                span.count {
                    color: #FFF;
                    display: inline-block;
                    font-size: 12px;
                    height: 20px;
                    line-height: 20px;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    right: 14px;
                    text-align: center;
                    top: 2px;
                    width: 20px;

                    &.notice-debug, &.notice-good {
                        background: $toolbar-color-debug;
                    }

                    &.notice-error, &.notice-bad {
                        background: $toolbar-color-error;
                    }

                    &.notice-fatal {
                        background: $toolbar-color-fatal;
                    }

                    &.notice-info {
                        background: $toolbar-color-info;
                    }

                    &.notice-log, &.notice-default {
                        background: $toolbar-color-log;
                    }

                    &.notice-warn {
                        background: $toolbar-color-warn;
                    }

                    &.notice-disabled {
                        background: #555;
                        color: #EEE;
                    }
                }

                span.label {
                    display: inline-block;
                    white-space: nowrap;
                }

                &:hover, &:focus, &:active {
                    background: #222222;
                    outline: none;
                }

                &.empty {
                    color: #666;

                    span.count {
                        background: #000;
                        color: #999;
                    }

                    &:hover, &:focus, &:active {
                        color: #999;
                        outline: none;

                        span.count {
                            color: #BBB;
                        }
                    }
                }
            }
        }

        &.basket span.label {
            width: 100px;
        }

        &.customer span.label {
            width: 120px;
        }

        &.geolocation span.label {
            width: auto;
        }

        &.messages span.label {
            width: 70px;
        }

        &.preferences span.label {
            width: 70px;
        }

        &.session span.label {
            width: 70px;
        }

        &.site span.label {
            width: 70px;
        }
    }
}
